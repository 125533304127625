<script setup>
import { useRouter } from 'nuxt/app';
import { useAuthenticationStore } from '~/stores/account/authentication.store';


const authStore = useAuthenticationStore();
const router = useRouter();
const localePath = useLocalePath()
const { $toast } = useNuxtApp()

const email = ref('')

const forgotPasswordReq = async () => {
    
    await authStore.forgotPassword({ email: email.value })
        .then((res) => {
            router.push({ path: localePath({ name: 'account.login' }) });
           $toast.addToast({ title: "New activation code has been sent to your email, Check your mailbox to confirm your account.", color: "green", position: 'top-10' })
        })
        .catch((err) => {
            let defaultMessage = 'Something Went Wrong Please Try Again'
            if(err.activation_code){
              defaultMessage=err.activation_code[0]
            }else if(err.response.data.email){
                defaultMessage=err.response.data.email[0];
            } 
           $toast.addToast({ title: defaultMessage, color: "red", position: 'top-10' })
        })
};
</script>


<template>
    <div class="relative">
        <header class="sticky top-0 z-10">
            <div class="px-4 h-20 bg-indigo-900 flex items-center justify-between text-white relative">
                <NuxtLink :to="localePath({ name: 'account.login' })">
                    <i class="fa-solid fa-chevron-left fa-xl"></i>
                </NuxtLink>
            </div>
        </header>
        <main class="mb-32 px-4">
            <div class="flex flex-col justify-center my-10 w-full text-center items-center">
                <img :src="`images/${appId}/logo-blue.webp`" alt="" class="w-44">
                <p class="mt-3 text-gray-500 text-xl">{{ $t('client.forget_password') }}</p>
            </div>
            <form class="*:mb-3">
                <div>
                    <input v-model="email" type="email" placeholder="Email"
                        class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md" />
                </div>
                <div @click="forgotPasswordReq" class="mt-16 w-full flex">
                    <a class="bg-indigo-900 text-white rounded-lg p-3 capitalize  w-full text-center">
                        {{ $t('client.confirm') }}
                    </a>
                </div>
            </form>
        </main>
    </div>
</template>